import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';

import Styles from './ContattaciSuccess.styles';
import Button from '@components/Button/Button';
import Onda from '@static/img/onda-404.svg';

const useStyles = createUseStyles(Styles);

const ContattaciSuccess = ({ to, label, ...props }) => {
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.textContainer}>
          <h1 className={classes.title}>Messaggio inviato!</h1>
          <p className={classes.subtitle}>
            Grazie per averci contattato. Ti risponderemo il prima possibile.
          </p>
        </div>

        <div className={classes.buttonContainer}>
          <Button to="/lezioni" label="VAI ALLE LEZIONI" />
        </div>
      </div>
      <img className={classes.wave} src={Onda} alt="Liveflow" />
    </div>
  );
};

ContattaciSuccess.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
};

export default ContattaciSuccess;
