import React from 'react';

import Layout from '@layout/Layout';
import Seo from '@components/Seo/Seo';

import ContattaciSuccess from '@components/ContattaciSuccess/ContattaciSuccess';

const ContattaciSuccessPage = ({ location }) => {
  const title = 'Messaggio inviato!';
  return (
    <Layout>
      <Seo title={title} url={location.pathname} />
      <ContattaciSuccess />
    </Layout>
  );
};

export default ContattaciSuccessPage;
